import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path1/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Props, Playground } from 'docz';
import { HoppinDesignProvider } from '../HoppinDesignProvider';
import { Button } from '../Button';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "hoppindesignprovider"
    }}>{`HoppinDesignProvider`}</h1>
    <p>{`The HoppinDesignProvider should wrap the main App component, it provides the theme to all the siblings.`}</p>
    <p>{`Make sure to set `}<inlineCode parentName="p">{`context='host'`}</inlineCode>{` or `}<inlineCode parentName="p">{`context='shadower'`}</inlineCode>{` in the to set primary colors, it defaults to `}<inlineCode parentName="p">{`context='shadower'`}</inlineCode>{`.`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`host`}</inlineCode>{` => primary is host pink`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`shadower`}</inlineCode>{` => primary is shadower blue`}</li>
    </ul>
    <h2 {...{
      "id": "props"
    }}>{`Props`}</h2>
    <Props of={HoppinDesignProvider} mdxType="Props" />
    <Playground __position={1} __code={'<HoppinDesignProvider>\n  <Button variant=\"full\" context=\"primary\">\n    Primary Button\n  </Button>\n</HoppinDesignProvider>\n<br />\n<HoppinDesignProvider context=\"shadower\">\n  <Button variant=\"full\" context=\"primary\">\n    Primary Button\n  </Button>\n</HoppinDesignProvider>\n<br />\n<HoppinDesignProvider context=\"host\">\n  <Button variant=\"full\" context=\"primary\">\n    Primary Button\n  </Button>\n</HoppinDesignProvider>'} __scope={{
      props,
      DefaultLayout,
      Props,
      Playground,
      HoppinDesignProvider,
      Button,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <HoppinDesignProvider mdxType="HoppinDesignProvider">
    <Button variant="full" context="primary" mdxType="Button">Primary Button</Button>
  </HoppinDesignProvider>
      <br />
      <HoppinDesignProvider context="shadower" mdxType="HoppinDesignProvider">
  <Button variant="full" context="primary" mdxType="Button">
    Primary Button
  </Button>
      </HoppinDesignProvider>
      <br />
  <HoppinDesignProvider context="host" mdxType="HoppinDesignProvider">
    <Button variant="full" context="primary" mdxType="Button">Primary Button</Button>
  </HoppinDesignProvider>
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      